import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { useNavigate } from "react-router-dom";
import "./loader.css"; // Make sure to create and import the CSS file for the loader

const Register = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [countries, setCountries] = useState([]);

  const [selectedCountry, setSelectedCountry] = useState({
    callingCode: "+91"
  });
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });

    const fetchCountries = async () => {
      try {
        const response = await fetch(
          "https://contactapi.the-firstclass.club/countries"
        );
        const data = await response.json();
        setCountries(data.country);
        
        // console.log(data.country);
      } catch (error) {
        console.error("Error fetching countries:", error);
      }
    };
    fetchCountries();
  }, []);

  const onSubmit = async (data) => {
    setLoading(true);

    // Create the body object
    const requestBody = {
      firstName: data.firstName,
      lastName: data.lastName,
      emailId: data.email,
      phoneNumber: data.phoneNumber,
      countryId: data.countryName,
      instagramId: data.instaId,
    };

    try {
      const response = await fetch(
        "https://contactapi.the-firstclass.club/registerInfluncer",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody), // Pass the requestBody variable
        }
      );
      
      if (response.ok) {
        const result = await response.json();
        
        if (result.success) {
          // Handle success response
          toastr.success("Registration successful!");
          reset();
        } else {
          toastr.error("User is already registered.");
          setTimeout(() => {
            navigate("/already-registered"); // Redirect to another page
          }, 1000);
        }
      } else {
        // Handle error response
        const error = await response.json();
        console.error("Error:", error);
        toastr.error("Registration failed. Please try again.");
      }  
    } catch (error) {
      console.error("Form submission error:", error);
      toastr.error("An error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };
 const handleCountryChange = (e) =>{
  const countryId = e.target.value;
  const selected = countries.find((country)=>country._id === countryId);
  console.log(selected)
  
  setSelectedCountry({
 
    callingCode: selected ? `+${selected.callingCode}` : ""
  });
 }

  return (
    <>
      <Helmet>
        <title>Influencer Register</title>
      </Helmet>

      <div className="register-page">
        <div className="register-herosection">
          <div className="register-inside">
            <h3 className="register-title">Registration</h3>
            <p className="register-para" data-aos="fade-down">
              We create successful campaigns that are impactful, tangible, and
              result oriented. By crafting content around your members, we
              enhance your type of clientele and boost your venue's reputation
            </p>
          </div>
        </div>
        <div className="register-form-below">
          <div className="container">
            <div className="register-formInside">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row gx-2 gx-md-4 gx-lg-5 gy-lg-4 gy-md-3 gy-2">
                  <div className="form-group col-md-6">
                    <label className="form-lebels" htmlFor="firstName">
                      First Name
                    </label>
                    <input
                      type="text"
                      className="form-control register-input"
                      id="firstName"
                      placeholder="First Name"
                      {...register("firstName", {
                        required: "First name is required",
                        pattern: {
                          value: /^[A-Za-z\s]+$/i,
                          message:
                            "Name should contain only letters and spaces",
                        },
                      })}
                    />
                    {errors.firstName && (
                      <span className="error-msg">
                        {errors.firstName.message}
                      </span>
                    )}
                  </div>
                  <div className="form-group col-md-6">
                    <label className="form-lebels" htmlFor="lastName">
                      Last Name
                    </label>
                    <input
                      type="text"
                      className="form-control register-input"
                      id="lastName"
                      placeholder="Last Name"
                      {...register("lastName", {
                        required: "Last name is required",
                        pattern: {
                          value: /^[A-Za-z\s]+$/i,
                          message:
                            "Name should contain only letters and spaces",
                        },
                      })}
                    />
                    {errors.lastName && (
                      <span className="error-msg">
                        {errors.lastName.message}
                      </span>
                    )}
                  </div>

                 
                  <div className="form-group col-md-6">
                    <label className="form-lebels" htmlFor="countryName">
                      Select Your Country
                    </label>
                    <div className="position-relative">
                      <select
                        className="form-control register-input"
                        id="countryName"
                        name="countryName"
                        {...register("countryName", {
                          required: "Country selection is required",
                        })}
                        onChange={handleCountryChange}
                      >
                        <option value="">Select Your Country</option>
                        {countries.map((country) => (
                          <option key={country._id} value={country._id}>
                            {country.name}
                          </option>
                        ))}
                      </select>
                      {errors.countryName && (
                        <span className="error-msg">
                          {errors.countryName.message}
                        </span>
                      )}
                      <img
                        className="down-arow-top"
                        src="/images/register/downaro.png"
                        alt="down arrow"
                      />
                    </div>
                  </div>

                  <div className="form-group col-md-6">
                    <label className="form-lebels" htmlFor="phoneNumber">
                      Phone Number
                    </label>
                 
                    <div className="registration-flag">
                      <div className="registration-number">
                        <div className="">
                         
                         
                            <span className="register-number">{selectedCountry.callingCode}</span>
                          
                          {/* <ul
                            className="dropdown-menu countrylist-top"
                            aria-labelledby="dropdownMenuButton1"
                          >
                            <li className="dropdown-search-bar">
                              <div className="dropdowninsideimage">
                                
                                <input
                                  type="text"
                                  className="form-control search-inputt"
                                  placeholder="Search Country Code"
                                  onChange={handleSearch}
                                  value={searchTerm}
                                />
                              </div>
                            </li>
                            <div className="contry-lists-below">
                            {
                              filteredCountries.map((country)=>(
                                <li key={country._id} className="dropdown-flag-list" onClick={()=>handleCountrySelect(country)}>
                               <a className="dropdown-item" href="#">
                               
                                <span className="flag-span">{country.code} +{country.callingCode}</span>
                              </a>
                              </li>
                              ))
                            }
                            </div>
                            
                          </ul> */}
                        </div>
                      </div>
                     
                       <input
                        type="tel"
                        className="form-control  phone-input"
                        id="phoneNumber"
                        placeholder="Enter Your Phone Number"
                        {...register("phoneNumber", {
                          required: "Phone number is required",
                          pattern: {
                            value: /^[0-9]*$/,
                            message: "Phone number should contain only numbers",
                          },
                        })}
                      />
                    
                    </div>
                    {errors.phoneNumber && (
                        <span className="error-msg">
                          {errors.phoneNumber.message}
                        </span>
                      )}
                  </div>
                  <div className="form-group col-md-6">
                    <label className="form-lebels" htmlFor="email">
                      Email
                    </label>
                    <input
                      type="email"
                      className="form-control register-input"
                      id="email"
                      placeholder="Enter Your Email"
                      {...register("email", {
                        required: "Email is required",
                        pattern: {
                          value: /^\S+@\S+$/i,
                          message: "Invalid email address",
                        },
                      })}
                    />
                    {errors.email && (
                      <span className="error-msg">{errors.email.message}</span>
                    )}
                  </div>
                  <div className="form-group col-md-6">
                    <label className="form-lebels" htmlFor="instaId">
                      Instagram ID
                    </label>
                    <input
                      type="text"
                      className="form-control register-input"
                      id="instaId"
                      placeholder="Instagram ID"
                      {...register("instaId", {
                        required: "Instagram ID is required",
                      })}
                    />
                    {errors.instaId && (
                      <span className="error-msg">
                        {errors.instaId.message}
                      </span>
                    )}
                  </div>
                  
                </div>
                <button
                  type="submit"
                  className="register-formBtn"
                  disabled={loading}
                >
                  {loading ? "Submitting..." : "Register Now"}
                </button>
       
              </form>
              {loading && <div className="loader"></div>}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Register;
