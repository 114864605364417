import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const AlreadyRegistered = () => {
  return (
    <>
        <Helmet>
            <title>Influencer Already Registered</title>
        </Helmet>
        <div className="already-registered">
            <div className="already-registered-section">
                <div className="already-registered-content">
                    <h2 className="alreadyregistered-heading">User Already Registered.</h2>
                    <p className="alreadyregistered-para">Your registration is under review. Please wait for admin approval.</p>
                </div>     
            </div>
        </div>
    </>  
  );
};

export default AlreadyRegistered;
