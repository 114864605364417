import { Route, Routes } from "react-router-dom";
import "./App.css";
import Layout from "./Pages/Layout/Layout";
import Landing from "./Pages/Landing/Landing";
import Venue from "./Pages/Venue/Venue";
import Register from "./Pages/Register/Register";
import Termcondition from "./Pages/TermCondition/Termcondition";
import Privacypolicy from "./Pages/PrivacyPolicy/Privacypolicy";
import Guests from "./Pages/Guests/Guests";
import ContactUs from "./Pages/contactus/ContactUs";
import InfluencerForm from "./Pages/InfluencerForm/InfluencrForm";
import AlreadyRegistered from "./Pages/AlreadyRegistered/AlreadyRegistered";
import { Helmet } from "react-helmet";

function App() {
  return (
    <>
      <Helmet>
        <link rel="canonical" href="https://the-firstclass.club/home" />
      </Helmet>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Landing />} />
          <Route path="/" element={<Landing />} />
          <Route path="/venues" element={<Venue />} />
          <Route path="/register" element={<Register />} />
          <Route path="/guests" element={<Guests />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/influencer-registration" element={<InfluencerForm />} />
          <Route path="/terms-and-conditions" element={<Termcondition />} />
          <Route path="/privacy-policy" element={<Privacypolicy />} />
          <Route path="/already-registered" element={<AlreadyRegistered />} />
          <Route path="*" element={<Landing />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
